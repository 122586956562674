import { default as loginoJfmschrY9Meta } from "/app/pages/(auth)/login.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: "login___en",
    path: "/login",
    component: () => import("/app/pages/(auth)/login.vue")
  },
  {
    name: "login___pl",
    path: "/pl/login",
    component: () => import("/app/pages/(auth)/login.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___pl",
    path: "/pl",
    component: () => import("/app/pages/index.vue")
  }
]